import React from 'react';
import { message, Modal, Tabs } from 'antd';
import { Dialog, Button} from '@/components';
import PrintDropdown from '../sale_order_detail/components/print-dropdown/PrintDropdown';
import { printBatch } from '../sale_order_detail/components/print-dropdown/PrintDropdown';
import { routerMap } from '@/router/agent_router';
import Layout from '@/layout/Layout';
import WebApi from '@/web/api';
import WebAuth from '@/web/WebAuth';
import { LibAuthCode } from 'lib/Auth';
import LibEnum from 'lib/enum';
import SaleOrderListTabPane from './SaleOrderListTabPane';
import { observer } from 'mobx-react';
import globalUserStore from '@/global_store/globalUserStore';

interface IState {
  activeKey: string;
}

@observer
export default class SaleOrderList extends React.PureComponent<RouterPropsType, IState> {

  private allRef = React.createRef<SaleOrderListTabPane>();
  private auditRef = React.createRef<SaleOrderListTabPane>();
  private outRef = React.createRef<SaleOrderListTabPane>();
  private deliverRef = React.createRef<SaleOrderListTabPane>();
  private doneRef = React.createRef<SaleOrderListTabPane>();
  private invalidRef = React.createRef<SaleOrderListTabPane>();
  private receivedRef = React.createRef<SaleOrderListTabPane>();

  constructor(props) {
    super(props);

    this.state = {
      activeKey: this.props.match?.params.status || 'all',
    };
  }

  render() {
    const { activeKey } = this.state;
    return <Tabs className="app-tabs" activeKey={activeKey} animated={false} onChange={key => {
        this.setState({
          activeKey: key
        });
      }} tabBarExtraContent={
        globalUserStore.from === 'YCH' ? null : <Layout.TabBarExtra>
          <Button authCode={LibAuthCode.saleOrder.create} type="primary" onClick={() => {
            this.props.history.push(routerMap.sale_order_create.path);
          }}>新增订货单</Button>
          {
            WebAuth.hasAuth(LibAuthCode.saleOrder.print) &&
            <PrintDropdown clickPrint={this.clickBatchPrint} clickPrintStatus={this.clickUpdatePrintStatus}/>
          }
          {/* <Button type="default" style={{marginLeft: 10}} icon={<i className="iconfont icon-shezhi"/>} onClick={() => this.setState({ drawerVisible: true })}>设置</Button> */}
          <Button.Refresh onClick={() => {
            this.getCurrentActiveKey()?.current?.refresh(true);
          }}/>
        </Layout.TabBarExtra>
      }>
        <Tabs.TabPane tab="全部" key="all">
          <SaleOrderListTabPane ref={this.allRef} history={this.props.history} orderStatus=""/>
        </Tabs.TabPane>

        <Tabs.TabPane tab="待审核" key="audit">
          <SaleOrderListTabPane ref={this.auditRef} history={this.props.history} orderStatus={LibEnum.SALE_ORDER_STATUS.WAIT_AUDIT.value}/>
        </Tabs.TabPane>

        <Tabs.TabPane tab="待出库" key="out">
          <SaleOrderListTabPane ref={this.outRef} history={this.props.history} orderStatus={LibEnum.SALE_ORDER_STATUS.WAIT_OUT_STOCK.value}/>
        </Tabs.TabPane>

        <Tabs.TabPane tab="待发货" key="deliver">
          <SaleOrderListTabPane ref={this.deliverRef} history={this.props.history} orderStatus={LibEnum.SALE_ORDER_STATUS.WAIT_DELIVER.value}/>
        </Tabs.TabPane>

        <Tabs.TabPane tab="已完成" key="done">
          <SaleOrderListTabPane ref={this.doneRef} history={this.props.history} orderStatus={LibEnum.SALE_ORDER_STATUS.FINISHED.value}/>
        </Tabs.TabPane>

        <Tabs.TabPane tab="已作废" key="invalid">
          <SaleOrderListTabPane ref={this.invalidRef} history={this.props.history} orderStatus={LibEnum.SALE_ORDER_STATUS.INVALID.value}/>
        </Tabs.TabPane>

        <Tabs.TabPane tab="待收款" key="received">
          <SaleOrderListTabPane ref={this.receivedRef} history={this.props.history} paymentStatus={LibEnum.PAYMENT_STATUS.WAIT_PAYMENT.value}/>
        </Tabs.TabPane>

        {/* <Tabs.TabPane tab="订货商品" key="2">
          <SaleDetailList/>
        </Tabs.TabPane> */}
      </Tabs>
  }

  // 批量打印
  clickBatchPrint = () => {
    const selectedRowKeys = this.getCurrentActiveKey()?.current?.getSelectedKeys();
    if (!Array.isArray(selectedRowKeys) || selectedRowKeys.length === 0) {
      message.warning('请至少选择 1 条订单');
      return;
    }

    WebApi.sale_order_print_data_list({
      saleOrderIdList: selectedRowKeys
    })
    .then((orderInfoList: any) => {
      printBatch(orderInfoList);
    })
    .catch(e => {
      console.log(e);
      message.error(e.message);
    });
  }

  clickUpdatePrintStatus = status => {
    const selectedRowKeys = this.getCurrentActiveKey()?.current?.getSelectedKeys();
    if (!Array.isArray(selectedRowKeys) || selectedRowKeys.length === 0) {
      message.warning('请至少选择 1 条订单');
      return;
    }

    Modal.confirm({
      title: '确认',
      content: `共选中 ${selectedRowKeys.length} 张订单，全部标为${
        status === '11' ? '未' : '已'
      }打印？`,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {

        WebApi.sale_order_print_status_update({
          saleOrderIdList: selectedRowKeys,
          printStatus: status,
        })
        .then(() => {
          this.getCurrentActiveKey()?.current?.refresh(false);
          message.success('保存成功');
        });
      },
    });
  }

  // 删除订单
  clickDelete = record => {
    Dialog.confirm({
      title: '删除确认',
      content: `确认删除订单 ”${record.id}“ 吗？`,
      onOk: () => {
        WebApi.sale_order_delete(record.id)
        .then(() => {
          showSuccess('删除成功');
          this.getCurrentActiveKey()?.current?.refresh(false);
        })
      }
    });
  }

  // 获取当前激活的页签
  private getCurrentActiveKey = () => {
    const {activeKey} = this.state;
    if (activeKey === 'all') {
      return this.allRef;
    }
    if (activeKey === 'audit') {
      return this.auditRef;
    }
    if (activeKey === 'out') {
      return this.outRef;
    }
    if (activeKey === 'deliver') {
      return this.deliverRef;
    }
    if (activeKey === 'done') {
      return this.doneRef;
    }
    if (activeKey === 'invalid') {
      return this.invalidRef;
    }
    if (activeKey === 'received') {
      return this.receivedRef;
    }
    return null;
  }
}