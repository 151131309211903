import MD5 from "./md5";

const LibUtils = {
  // 手机号脱敏
  hideMobile(mobile: string) {
    if (typeof mobile !== 'string') return;
    return mobile.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
  },
  // 密码加密
  cryptoPassword(password: string) {
    return MD5.hex_md5(password || '');
  },
  // 字符串拼接，避免undefined
  stringJoin(strings: string[], spread?: string) {
    if (!Array.isArray(strings)) return '';
    return strings.filter(item => !!item).join(spread || '');
  },
  // 金额格式化
  priceFormate(value = '', placeholderValue = '') {
    const stringArray = value.split('.');
    if (stringArray.length > 2) {
      value = placeholderValue;
    }
    // 有小数点，不能超3位
    if (stringArray.length === 2 && stringArray[1].length > 2) {
      value = placeholderValue;
    }
    if (isNaN(Number(value))) {
      value = placeholderValue;
    }
    if (Number(value) < 0) {
      value = placeholderValue;
    }
    return value;
  },
  enumToOptions(data: {[key: string]: any}, excludeAll?: boolean) {
    const result: {name: string; value: string}[] = excludeAll ? [] : [
      {
        name: '全部',
        value: ''
      }
    ]
    for(let key in data) {
      result.push(data[key]);
    }
    return result;
  },
  ascSort(arr: any[], field: string) {
    var len = arr.length;
    console.time('升序冒泡排序耗时');
    for (var i = 0; i < len; i++) {
      for (var j = 0; j < len - 1 - i; j++) {
        if (arr[j][field] > arr[j+1][field]) {        //相邻元素两两对比
          var temp = arr[j+1];        //元素交换
          arr[j+1] = arr[j];
          arr[j] = temp;
        }
      }
    }
    console.timeEnd('升序冒泡排序耗时');
    return arr;
  },
  descSort(arr: any[], field: string) {
    var len = arr.length;
    console.time('降序冒泡排序耗时');
    for (var i = 0; i < len; i++) {
      for (var j = 0; j < len - 1 - i; j++) {
        if (arr[j][field] < arr[j+1][field]) {        //相邻元素两两对比
          var temp = arr[j+1];        //元素交换
          arr[j+1] = arr[j];
          arr[j] = temp;
        }
      }
    }
    console.timeEnd('降序冒泡排序耗时');
    return arr;
  }  
};

export default LibUtils;