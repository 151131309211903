import { Dialog } from "@/components";
import WebApi from "@/web/api";

export const auditSaleOrder = (id: string) => {
  return new Promise((resolve) => {
    Dialog.confirm({
      title: '审核通过',
      content: `确定审核通过订货单 “${id}” 吗?`,
      onOk: async () => {
        WebApi.sale_order_audit({
          saleOrderId: id,
        })
        .then(() => {
          showSuccess.save();
          resolve(null);
        })
      },
    });
  });
}

export const cancelSaleOrder = (id: string) => {
  return new Promise((resolve) => {
    Dialog.confirm({
      title: '作废',
      content: `确定作废订货单 “${id}” 吗?`,
      onOk: async () => {
        WebApi.sale_order_invalid({
          saleOrderId: id,
        })
        .then(() => {
          showSuccess.save();
          resolve(null);
        })
      },
    });
  });
}

export const deleteSaleOrder = (id: string) => {
  return new Promise((resolve) => {
    Dialog.confirm({
      title: '删除',
      content: `确定删除订货单 “${id}” 吗?`,
      onOk: async () => {
        WebApi.sale_order_delete({
          saleOrderId: id,
        })
        .then(() => {
          showSuccess.save();
          resolve(null);
        })
      },
    });
  });
}