import React from 'react';

interface PrintStatusProps {
  children?: JSX.Element
}

const PrintStatus: any = {};

PrintStatus.UnFinished = ({children}) => <div style={{
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#faad14', 
}}>
  <i className="iconfont icon-weiwancheng" style={{marginRight: 2, fontSize: 16, lineHeight: 1}}></i>
  {children}
</div>

PrintStatus.Finished = ({children}) => <div style={{
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#52c41a'
}}>
  <i className="iconfont icon-success" style={{marginRight: 2, fontSize: 16,}}></i>
  {children}
</div>

export default PrintStatus;