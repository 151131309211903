import React from 'react';
import {ViewTable, Filter, Layout} from '@/components';
import SelectShowDataIndex, { getIdsFromStorage } from '../components/select-columns/SelectColumns';
import columnsData from './sale_order_columns';
import { routerMap } from '@/router/agent_router';
import WebApi, { YchApi } from '@/web/api';
import WebAuth from '@/web/WebAuth';
import { LibAuthCode } from 'lib/Auth';
import LibUtils from 'lib/utils';
import LibEnum from 'lib/enum';
import { IFilterField } from '@/components/filter/Filter';
import LibSaleOrderFunction from 'lib/function/SaleOrderFunction';
import { auditSaleOrder, cancelSaleOrder, deleteSaleOrder } from '../saleOrderFunction';
import globalUserStore from '@/global_store/globalUserStore';
import { observer } from 'mobx-react';

interface IProps extends RouterPropsType {
  orderStatus?: string;
  paymentStatus?: string;
}

interface IState {
  visible: boolean;
  drawerVisible: boolean;
  selectedRowKeys: React.Key[];
  columns: any[];
}


@observer
export default class SaleOrderListTabPane extends React.PureComponent<IProps, IState> {

  private table = React.createRef<ViewTable>();

  constructor(props) {
    super(props);

    const cache: any[] = getIdsFromStorage();

    this.state = {
      visible: false,
      drawerVisible: false,
      selectedRowKeys: [],
      columns: columnsData.filter(item => cache.includes(item.dataIndex)),
    };
  }

  render() {
    const { history, orderStatus } = this.props;
    const { selectedRowKeys, drawerVisible, columns } = this.state;

    const fields: IFilterField[] = [
      {
        field: 'paymentStatus',
        name: '付款状态',
        options: LibUtils.enumToOptions(LibEnum.PAYMENT_STATUS),
        width: 80,
        type: 'select',
      },
      {
        field: 'keyword',
        name: '搜索',
        type: 'input',
        placeholder: '订单号/客户/收货人/收货人手机号'
      },
    ];
    if (!orderStatus) {
      fields.unshift({
        field: 'orderStatus',
        name: '订单状态',
        type: 'select',
        width: 80,
        options: LibUtils.enumToOptions(LibEnum.SALE_ORDER_STATUS)
      });
    }

    return <Layout.TabPage>
      <Filter
        ych
        fields={fields}
        onChange={values => {
          this.table.current?.reset(values);
        }}
      />

      {/* table 数据 */}
      <ViewTable
        flex1
        ych
        ref={this.table}
        rowSelection={globalUserStore.isYch() ? undefined : {
          selectedRowKeys,
          onChange: selectedRowKeys => {
            this.setState({ selectedRowKeys });
          }
        }}
        columns={columns}
        actionButtons={globalUserStore.from === 'YCH' ? [] : [
          {
            type: 'detail',
            onClick: (record) => {
              history.push(routerMap.sale_order_detail.getPath(record.saleId, record.orderStatus));
            }
          },
          {
            type: 'invalid',
            tip: '审核通过',
            iconClassName: 'icon-tongguo',
            hidden: !WebAuth.hasAuth(LibAuthCode.saleOrder.update) || !LibSaleOrderFunction.isWaitAudit(orderStatus),
            onClick: (row) => {
              auditSaleOrder(row.id).then(() => {
                this.table.current?.refresh({}, false);
              });
            }
          },
          {
            type: 'invalid',
            tip: '作废',
            hidden: !WebAuth.hasAuth(LibAuthCode.saleOrder.update) || !LibSaleOrderFunction.isWaitAudit(orderStatus),
            iconClassName: 'icon-butongyi',
            onClick: (row) => {
              cancelSaleOrder(row.id).then(() => {
                this.table.current?.refresh({}, false);
              });
            }
          },
          {
            type: 'delete',
            hidden: !WebAuth.hasAuth(LibAuthCode.saleOrder.delete) || orderStatus !== '99',
            onClick: (record) => {
              deleteSaleOrder(record.id).then(() => {
                this.table.current?.refresh({}, false);
              });
            }
          }
        ]}
        fetch={data => {
          if (globalUserStore.isYch()) {
            return YchApi.sale_order_paging({orderStatus: this.props.orderStatus, ...data});
          }
          return WebApi.sale_order_paging({orderStatus: this.props.orderStatus, paymentStatus: this.props.paymentStatus, ...data});
        }}
        rowKey={(row: any) => row.saleId}/>

      <SelectShowDataIndex
        visible={drawerVisible}
        data={columns}
        onOk={(ids) => {
          this.setState({
            columns: columnsData.filter(item => ids.includes(item.dataIndex)),
            drawerVisible: false,
          });
        }}
        onCancel={() => this.setState({drawerVisible: false})}
      />
    </Layout.TabPage>
  }

  refresh = (isTip?: boolean) => {
    this.table.current?.refresh({}, !!isTip);
  }

  reset = () => {
    this.setState({
      selectedRowKeys: [],
    });
    this.table.current?.refresh();
  }

  getSelectedKeys = () => {
    return this.state.selectedRowKeys as string[];
  }
}