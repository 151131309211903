import React from 'react';
import { Tag, Tooltip } from 'antd';
import PrintStatus from '../components/print-status/PrintStatus';
import { paymentEnumMap, saleEnumMap } from '@/enum-data/enum';
import { routerMap } from '@/router/agent_router';
import { Link, Status } from '@/components';
import globalUserStore from '@/global_store/globalUserStore';
import { IViewTableColumn } from '@/components/table/ViewTable';

export default [
  {
    title: '订单号',
    dataIndex: 'saleId',
    width: 200,
    align: 'center',
    render(text, record: any) {
      if (globalUserStore.isYch()) return text;
      return <Link to={routerMap.sale_order_detail.getPath(record.saleId, record.orderStatus)}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {text}
          {
            record.returnFlag === '1' &&
            <div style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              lineHeight: 1,
            }}>
              <Tooltip title="退回审核">
                <i className="iconfont icon-tui" style={{
                  fontSize: 22,
                  color: '#eb544c',
                }}></i>
              </Tooltip>
            </div>
          }
        </div>
        {/* {record.returnFlag === '1' && (
          <span style={{ color: 'gray', fontSize: 12 }}>{record.auditReason}</span>
        )} */}
      </Link>
    },
  },
  {
    title: '下单时间',
    dataIndex: 'createdAt',
    align: 'center',
    width: 200,
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatusDesc',
    width: 100,
    align: 'center',
    render: (text, record: any) => {
      return <Status.Order status={saleEnumMap(record.orderStatus) as any}>{text}</Status.Order>
    },
  },
  {
    title: '收款状态',
    dataIndex: 'receivedStatusDesc',
    width: 100,
    align: 'center',
    render: (text, record: any) => {
      return <Status.Payment status={paymentEnumMap(record.receivedStatus) as any}>{text}</Status.Payment>
    },
  },
  {
    title: '客户',
    dataIndex: 'customerName',
    width: 200,
  },
  // {
  //   title: '客户编号',
  //   dataIndex: 'userId',
  //   align: 'center',
  //   width: 200,
  // },
  {
    title: '订单金额（元）',
    dataIndex: 'totalAmount',
    align: 'right',
    width: 120,
  },
  {
    title: '应付金额（元）',
    dataIndex: 'payAmount',
    align: 'right',
    width: 120,
  },
  {
    title: '优惠金额（元）',
    dataIndex: 'favorableAmount',
    width: 120,
    align: 'right'
  },
  {
    title: '数量合计',
    dataIndex: 'totalCount',
    width: 120,
    align: 'right'
  },
  {
    title: '下单方式',
    dataIndex: 'orderWayDesc',
    width: 150,
    align: 'center',
    render: (text, record: any) => {
      if (record.orderWay === '11') {
        return <Tag color="blue">{text}</Tag>;
      }
      return text;
    },
  },
  {
    title: '打印状态',
    width: 150,
    align: 'center',
    dataIndex: 'printStatus',
    render: (printStatus, record: any) => {
      if (printStatus === '11') {
        return <PrintStatus.UnFinished>未打印</PrintStatus.UnFinished>
      }
      if (printStatus === '12') {
        return <PrintStatus.Finished>已打印</PrintStatus.Finished>
      }
      return record.printStatusDesc
    },
  },
  {
    title: '收货人',
    dataIndex: 'consigneeName',
    width: 200,
  },
  {
    title: '联系方式',
    dataIndex: 'consigneeMobile',
    width: 150,
    align: 'center',
  },
  {
    title: '收货地址',
    dataIndex: 'consigneeAddress',
    width: 400,
  },
  {
    title: '备注',
    dataIndex: 'orderRemark',
    width: 200,
  },
] as IViewTableColumn[];