import React from 'react';
import {Drawer, Button, Checkbox } from 'antd';
import columnsData from '../../sale_order_list/sale_order_columns';

const disabledList = [ 'index', 'saleId', 'customerName', 'createdAt' ];
const filterList = ['action'];

export default React.memo(({
  visible = false,
  data = [],
  onCancel = () => {},
  onOk = () => {}
}: any) => {
  const list = columnsData.map(item => {
    return {
      label: item.title,
      value: item.dataIndex,
    };
  }).filter(item => !filterList.includes(item.value));
  let checkedIdArray = data.map(item => item.dataIndex);
  return <Drawer
    title="选择显示字段"
    width={300}
    closable={false}
    visible={visible}
    bodyStyle={{ paddingBottom: 80 }}
    footer={
      <div>
        <Button onClick={onCancel} style={{ marginRight: 8 }}>
          取消
        </Button>
        <Button onClick={() => {
          localStorage.setItem('$$_o_c_s', checkedIdArray.join(','));
          onOk(checkedIdArray);
        }} type="primary">确定</Button>
      </div>
    }
  >
    <Checkbox.Group
      defaultValue={checkedIdArray.concat(disabledList)}
      onChange={(checkedValues) => {
        checkedIdArray = checkedValues;
      }}
    >
      {list.map(item => {
        return <div style={{marginBottom: 10}} key={item.value}>
          <Checkbox
            disabled={disabledList.includes(item.value)}
            value={item.value}>
            {item.label}
          </Checkbox>
        </div>
      })}
    </Checkbox.Group>
  </Drawer>
});

export const getIdsFromStorage = () => {
  const checkedIdString = localStorage.getItem('$$_o_c_s');
  let defaultIds: any = [];
  if (checkedIdString === null) {
    defaultIds = columnsData.map(item => item.dataIndex);
  } else {
    defaultIds = checkedIdString.split(',');
  }
  return defaultIds;
};